import Icon from '@chakra-ui/icon';

export default function AddPolygonIcon() {
    return (
        <Icon viewBox='0 0 24 24' fontSize='lg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14 2.5C14 2.5969 13.9945 2.69252 13.9838 2.78655L19.7292 4.73529C20.1818 4.28108 20.8081 4 21.5 4C22.8807 4 24 5.11929 24 6.5C24 7.88071 22.8807 9 21.5 9C21.1722 9 20.8592 8.93691 20.5723 8.82222L15.1494 17.6213C15.6709 18.0795 16 18.7513 16 19.5C16 20.8807 14.8807 22 13.5 22C12.1193 22 11 20.8807 11 19.5C11 19.1664 11.0654 18.848 11.184 18.557L3.77818 11.649C3.40416 11.8719 2.96704 12 2.5 12C1.11929 12 0 10.8807 0 9.5C0 8.11929 1.11929 7 2.5 7C3.0692 7 3.59397 7.19022 4.0142 7.51056L9.20962 3.50364C9.07481 3.19645 9 2.85696 9 2.5C9 1.11929 10.1193 0 11.5 0C12.8807 0 14 1.11929 14 2.5ZM4.94879 10.0059C4.98236 9.84252 5 9.67332 5 9.5C5 9.42694 4.99687 9.35461 4.99073 9.28315L10.7096 4.87249C10.958 4.95521 11.2238 5 11.5 5C12.0359 5 12.5325 4.83136 12.9395 4.54426L19.002 6.60055C19.0121 6.85595 19.0605 7.1015 19.1416 7.33162L13.1697 17.0216C12.9782 17.0469 12.7936 17.0938 12.6183 17.1599L4.94879 10.0059Z'
                fill='#2D3748'
            />
        </Icon>
    );
}
